(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// import contactForm from "./modules/contactForm.js";
// import teste from "./modules/global/teste.js";

//     // contactForm();
//     teste();

jQuery(document).ready(function ($) {
  $('[data-form="dados-ebook"]').submit(function (e) {
    e.preventDefault();
    var form = $(this),
      formData = new FormData(this);
    var loaderBox = $("[data-loader-box]");
    var wp_nonce = $(".wp_nonce").val();
    var retornoForm = $('.box-input-submit > div');
    var retornoFormP = $('.box-input-submit > div p');
    loaderBox.addClass("loading");
    $.ajax({
      url: form.attr("action"),
      data: formData,
      //form.serialize()
      dataType: "json",
      type: "post",
      cache: false,
      contentType: false,
      processData: false,
      headers: {
        authorization: wp_nonce
      },
      beforeSend: function beforeSend(x, o) {
        console.log("Enviando Dados...");
        if (retornoFormP) {
          retornoFormP.hide();
        }
      },
      success: function success(response) {
        if (response.status) {
          window.location.href = response.data.base_url + "/obrigado-intrepida-trupe";
          return;
        }
        if (!response.status) {
          retornoForm.append("<p>" + response.msg + "</p>");
          loaderBox.removeClass("loading");
        }
      },
      complete: function complete() {
        // loaderBox.removeClass("loading");
      },
      error: function error(XMLHttpRequest, textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  });
});

},{}]},{},[1]);
